/*
 * Copyright (C) 2020-2024 by Savoir-faire Linux
 *
 * This program is free software; you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation; either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import { Blueprint } from "views/Blueprints/Blueprints";

export const getBlueprintsOptions = (blueprints: Blueprint[]) => {
  if (blueprints.length === 0) {
    return [{ value: 0, label: "No blueprint found" }];
  }

  return blueprints.map((blueprint, i) => ({
    value: i,
    label: blueprint.name,
  }));
};
